import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Map from '../components/Map';
import Content, { HTMLContent } from '../components/Content';

export const FindUsPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{title}</h2>
            <PageContent className="content" content={content} />
            <div className="columns">
              <div className="column is-12">
                <div className="map-wrapper">
                  <Map
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCHA9nQ6N2P3g9TjNxMdX9IVGYccIqlc8I&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    link={
                      'https://www.google.com/maps/place/Mpsk/@52.6054682,-0.2802918,17z/data=!3m1!4b1!4m5!3m4!1s0x4877f1459cfc924d:0xc9bd9c4d4e9df44b!8m2!3d52.6054682!4d-0.2781031'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FindUsPageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const FindUsPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <FindUsPageTemplate contentComponent={HTMLContent} title={post.frontmatter.title} content={post.html} />
    </Layout>
  );
};

FindUsPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default FindUsPage;

export const findUsPageQuery = graphql`
  query FindUsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
